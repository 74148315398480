import React from 'react'

const ProgressBar = ({bgcolor,progress,height}) => {
	
	const Parentdiv = {
		height: height,
		backgroundColor: 'whitesmoke',
		borderRadius: 10,
		margin: 20,
	}
	
	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: bgcolor,
		borderRadius:10,
		textAlign: 'right'
	}
	
	const progresstext = {
		padding: 10,
		color: 'black',
		fontWeight: 900
	}
	
	return (
	<div style={Parentdiv}>
	<div style={Childdiv}>
        {/*<span style={progresstext}>{`${progress}%`}</span>*/}
	</div>
	</div>
	)
}

export default ProgressBar;
