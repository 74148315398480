import HTTPService from "./http.service";

const authService = {
  //get video for 4th screen
  async getVideoByName(name) {
    try {
      const { data } = await HTTPService.get("/track/by_name/" + name);
      return data;
    } catch (e) {
      console.log(e);
    }
  },

  //upload audio record to server
  async sendAudioApi(track_name, payload) {
    try {
      const formData = new FormData();
      formData.append("recorded_audio", payload);
      const { data } = await HTTPService.post_audio(
        "track/by_name/" + track_name,
        formData
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },

  //upload audio record to server
  async sendLyricsAPI(id, payload) {
    try {
      const formData = new FormData();
      formData.append("lyrics", payload);
      const { data } = await HTTPService.post("track/lyrics/" + id, {
        lyrics: payload,
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};
export default authService;
