import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
// import videourl from '../../assets/sampleVideo.mp4'
import authService from '../../service/auth.service';
import Progressbar from './progressBar'

const VideoPlayer = ({ handleProgressBar, output_id, videoRef, playingFlag, output}) => {

    const [state, setState] = useState({
        videourl: ""
    });

    const getServerVideo = async () => {
        //request made
        let response = await authService.getVideoByName(output_id);
        console.log("code is here..")
        console.log(output)
        if (output) {
            // setState({ ...state, videourl: response.output_video });
            setState({ ...state, videourl: response.output_video_without_vocal });
        } else {
            console.log("code is here")
            setState({ ...state, videourl: response.output_video_without_vocal });
        }
        //else setState({ ...state, videourl: output_id });

    }

    useEffect(() => {
        getServerVideo();
    }, []);

    return (
        <div className="video-container">
            <ReactPlayer
                ref={videoRef}
                url={state.videourl}
                muted={false}
                playing={playingFlag}
                width={"100%"}
                onProgress={handleProgressBar}
            />
        </div>
    )
};

export default VideoPlayer;

