import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './header.css';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import mtvLogo from '../../assets/logo.jpg'
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
let history;
let homeRef;
let karokeBoothRef;
let howToUseRef;
let homemobileRef;
let karokeBoothmobileRef;
let howToUsemobileRef;

export const karokeBooth = () => {
    history.push('/karaokebooth')
    //history.push('/')
    homeRef.current.classList.remove("active");
    karokeBoothRef.current.classList.add("active");
    howToUseRef.current.classList.remove("active");

    homemobileRef.current.classList.remove("active");
    karokeBoothmobileRef.current.classList.add("active");
    howToUsemobileRef.current.classList.remove("active");
}
const Header = (props) => {
    const [menu_toggle, setMenuToggle] = useState(false);
    history = useHistory();
    homeRef = useRef();
    karokeBoothRef = useRef();
    howToUseRef = useRef();
    homemobileRef = useRef();
    karokeBoothmobileRef = useRef();
    howToUsemobileRef = useRef();

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        if (window.location.pathname === "/karaokebooth" || (window.location.pathname).includes("/record/")) {
            homeRef.current.classList.remove("active");
            karokeBoothRef.current.classList.add("active");
            howToUseRef.current.classList.remove("active");
            homemobileRef.current.classList.remove("active");
            karokeBoothmobileRef.current.classList.add("active");
            howToUsemobileRef.current.classList.remove("active");
        }

        return () => {
            window.removeEventListener("beforeunload", alertUser);
        }
    }, [])
    const alertUser = (e) => {
        if (localStorage.getItem("finishCarousal") === "true") {
            localStorage.setItem('carousalVisible', 'true')
            props.setCarousalVisible('carousalNotVisible');
            console.log("unmount")
        }
    };
    const toggleMenu = () => {
        setMenuToggle(!menu_toggle);
    }
    const karokeBoothMobile = () => {
        toggleMenu()
        karokeBooth()
    }
    const homePage = () => {
        history.push('/')
        karokeBoothRef.current.classList.remove("active");
        homeRef.current.classList.add("active");
        howToUseRef.current.classList.remove("active");
    }
    const homePageMobile = () => {
        history.push('/')
        toggleMenu()
        karokeBoothmobileRef.current.classList.remove("active");
        homemobileRef.current.classList.add("active");
        howToUsemobileRef.current.classList.remove("active");
    }
    const howToUse = () => {
        localStorage.setItem('carousalVisible', 'false')
        props.setCarousalVisible('carousalVisible');
        karokeBoothRef.current.classList.remove("active");
        homeRef.current.classList.remove("active");
        howToUseRef.current.classList.add("active");
        history.push('/karaokebooth')
    }
    const howToUseMobile = () => {
        localStorage.setItem('carousalVisible', 'false')
        props.setCarousalVisible('carousalVisible');
        toggleMenu()
        karokeBoothmobileRef.current.classList.remove("active");
        homemobileRef.current.classList.remove("active");
        howToUsemobileRef.current.classList.add("active");
        history.push('/karaokebooth')
    }

    return (
        <div>
            <nav className="nav">
                <div className="container-fluid d-flex justify-content-between">
                    <div className="nav-element logo-container" onClick={homePage}>
                        <img id="mtv-logo" src={mtvLogo} alt="mtv" />
                    </div>
                    <div className="d-flex nav-element">
                        <div className="navlink active" ref={homeRef} onClick={homePage}>home</div>
                        <div className="navlink" ref={karokeBoothRef} onClick={karokeBooth}>karaoke booth</div>
                        <div className="navlink" ref={howToUseRef} onClick={howToUse}>how to use</div>
                        <div id="hamburger">
                            <div id="nav-icon3" onClick={toggleMenu} className={menu_toggle ? 'open' : null}>
                                <div id="span_container">
                                    <span className="normal">
                                        <div className="normal_container">
                                            <div id="fill1"></div>
                                            <div id="gap1"></div>
                                        </div>
                                    </span>
                                    <span className="normal">
                                        <div className="normal_container">
                                            <div id="gap2"></div>
                                            <div id="fill2"></div>
                                        </div>
                                    </span>
                                    <span className="normal">
                                        <div className="normal_container">
                                            <div id="gap3"></div>
                                            <div id="fill3"></div>
                                            <div id="gap4"></div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="nav-tray" className={menu_toggle ? 'open' : null}>
                    <div className="moblink-container">
                        <div className="moblink active" ref={homemobileRef} onClick={homePageMobile}>home</div>
                        <div className="moblink " ref={karokeBoothmobileRef} onClick={karokeBoothMobile}>karaoke booth</div>
                        <div className="moblink " ref={howToUsemobileRef} onClick={howToUseMobile}>how to use</div>
                    </div>
                </div>
            </nav>
        </div >
    )
};

export default Header;