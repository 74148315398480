import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import './karaoke-booth.css'
import closeIcon from './../../assets/close_white.png'
import { useHistory } from 'react-router-dom';
import authService from '../../service/auth.service';

const EditLyricsFour = (props) => {
    const history = useHistory();

    const foodRef = useRef();
    const foodRefCopy = useRef();

    // const twoRef = useRef();
    // const threeRef = useRef();
    // const fourRef = useRef();
    // const fiveRef = useRef();

    const delay = ms => new Promise(res => setTimeout(res, ms));
    const checkTrackStatus = async (name) => {
        let response = await authService.getVideoByName(name)
        if (response) {
            return response
        } else {
            await delay(5000);
            return checkTrackStatus(name)
        }

    }

    const saveLyricsAPI = async () => {
        let array = [foodRef.current, foodRefCopy.current]
        let i;
        let flag = false;
        let focusFlag = -1;
        let lyricsPayload = []
        for (i = 0; i <= array.length; i++) {
            if (array[i]) {
                if (array[i].value === '') {
                    if (focusFlag === -1) {
                        flag = true;
                        array[i].focus();
                        focusFlag = i;
                    }
                    array[i].classList.add('lyrics-text-container-error');
                }
                else lyricsPayload.push(array[i].value)
            }
        }
        console.log(lyricsPayload)
        if (!flag) {
            // api call and redirect to record screen
            props.setLoader(true);
            let response = await authService.sendLyricsAPI("135", lyricsPayload);
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Processing',
                label: 'Kya Hai Banaya'
            });
            await checkTrackStatus(response.id)
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Ready',
                label: 'Kya Hai Banaya'
            });
            props.setLoader(false);
            console.log(response.id)
            history.push('/record/' + response.id);
            window.location.reload();

        }
    }

    return (
        <div className={props.className} ref={props.editLyricsRef}>
            <div className="lyrics-container container-fluid d-flex flex-column justify-content-between">
                <div className="crossLyrics d-flex justify-content-between">
                    <div className="lyrics-header-spacer"></div>
                    <div className="edit-text-header">Customize Lyrics</div>
                    <img src={closeIcon} alt="" onClick={props.toggleLyrics} />
                </div>

                <div className="lyrics-text-container d-flex flex-column justify-content-start">
                    <div className="lyrics-line">
                        Some time back I had my food
                    </div>
                    <div className="lyrics-line">
                        I’m feeling good.
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={foodRef} onChange={e => {
                                foodRef.current.classList.remove("lyrics-text-container-error")
                                foodRefCopy.current.value = foodRef.current.value;
                            }
                            } type="text" className='refresh' placeholder='Daal bhindi chawal' id='answer2' size='6' />
                            <label htmlFor="answer1">(enter food)</label>
                        </span> is what I ate
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={foodRefCopy} onChange={e => {
                                foodRefCopy.current.classList.remove("lyrics-text-container-error")
                                foodRef.current.value = foodRefCopy.current.value;
                            }} type="text" className='refresh' placeholder='Daal bhindi chawal' id='answer3' size='6' />
                            <label htmlFor="answer1">(enter food)</label>
                        </span> is what I ate
                    </div>
                    <div className="lyrics-line">
                        I’m not hungry, I’m feeling wonderful.
                    </div>
                </div>
                <div className="lyrics-controls container-fluid d-flex justify-content-center justify-content-lg-end">
                    {/* <div className="" onClick={props.resetLyrics}>
                        restore
                    </div> */}
                    <div className="save-proceed" onClick={saveLyricsAPI}>
                        save and proceed
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EditLyricsFour;