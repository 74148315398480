import React, { useState } from 'react';
import './recording.css'
import ProgressBar from './progressBar';
import editLyricsIcon from './../../assets/redit.png';
import recIcon from './../../assets/play.png'
import pauseIcon from './../../assets/pause.png'
import saveIcon from './../../assets/download.png'
import stopIcon from './../../assets/stop_play.png'
import backIcon from './../../assets/left_arrow.png'
import shareIcon from './../../assets/share.png'


const DownloadController = (props) => {
    //const saveClassName="right-controller disabled";
    const [cssDownload, setCssDownload] = useState("toggle-download visibleOff")
    const toggleDownload = () => {
        console.log()
        if (cssDownload === "toggle-download visibleOff") setCssDownload("toggle-download visibleON")
        else setCssDownload("toggle-download visibleOff")

    }
    return (
        <div>
            <div className="back" onClick={props.downlaodBack}>
                <img src={backIcon} alt="" />
            </div>
            <div id="controller-container">
                <ProgressBar bgcolor="black" progress={props.VideoPreviewProgress} height={4} />
                <div className='container-fluid d-flex justify-content-between'>
                    <div className="left-controller d-flex justify-content-start">
                        {/* <div onClick={props.shareWhatsapp} className="center-control rec desk">
                            <img key={'1'} src={shareIcon} />
                            <div className="control-text">share</div>
                        </div> */}
                    </div>
                    <div className='center-controller d-flex justify-content-md-center justify-content-sm-center'>
                        {/* <div onClick={props.shareWhatsapp} className="center-control mob share">
                            <img key={'2'} src={shareIcon} />
                            <div className="control-text">share</div>
                        </div> */}
                        {
                            props.state.downlaodPlay === false ?
                                (
                                    <div onClick={props.previewPlayAudio} className="center-control rec">
                                        <img key={'3'} src={recIcon} />
                                        <div className="control-text">play</div>
                                    </div>
                                )
                                :
                                (
                                    <div onClick={props.previewPlayAudio} className="center-control rec">
                                        <img key={'4'} src={pauseIcon} />
                                        <div className="control-text">pause</div>
                                    </div>
                                )
                        }
                        <div onClick={props.previewStopAudio} className="center-control stop download">
                            <img key={'5'} src={stopIcon} />
                            <div className="control-text">stop</div>
                        </div>

                        <div className="save-container mob download">
                            <div id="downlaodToggle" className={cssDownload}>
                                <div id="download-option1" className="download-option" onClick={props.saveTrackAudio}>
                                    save as audio(.mp3)
                                </div>
                                <div id="download-option2" className="download-option" onClick={props.saveTrackVideo}>
                                    save as video(.mp4)
                                </div>
                            </div>
                            <div onClick={props.saveTrackVideo}>
                                <img key={'6'} src={saveIcon} />
                                <div className="control-text">download</div>
                            </div>
                        </div>
                    </div>

                    <div className="right-controller d-flex justify-content-end" >
                        <div className="save-container desk download" >
                            <div id="downlaodToggle" className={cssDownload}>
                                <div id="download-option1" className="download-option" onClick={props.saveTrackAudio}>
                                    save as audio(.mp3)
                                </div>
                                <div id="download-option2" className="download-option" onClick={props.saveTrackVideo}>
                                    save as video(.mp4)
                                </div>
                            </div>
                            <div onClick={props.saveTrackVideo}>
                                <img key={'7'} src={saveIcon} />
                                <div className="control-text">download</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DownloadController;