import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';
import './karaoke-booth.css'
import closeIcon from './../../assets/close_white.png'
import { useHistory } from 'react-router-dom';
import authService from '../../service/auth.service';

const EditLyricsThree = (props) => {
    const history = useHistory();

    const eventRef = useRef();
    const yearRef = useRef();
    const ageRef = useRef();
    const nameRef = useRef();
    const relationRef = useRef();

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkTrackStatus = async (name) => {
        let response = await authService.getVideoByName(name)
        if (response) {
            return response
        } else {
            await delay(5000);
            return checkTrackStatus(name)
        }

    }
    const saveLyricsAPI = async () => {
        let array = [eventRef.current, yearRef.current, ageRef.current,
        nameRef.current, relationRef.current]
        let i;
        let flag = false;
        let focusFlag = -1;
        let lyricsPayload = []
        for (i = 0; i <= array.length; i++) {
            if (array[i]) {
                if (array[i].value === '') {
                    if (focusFlag === -1) {
                        flag = true;
                        array[i].focus();
                        focusFlag = i;
                    }
                    array[i].classList.add('lyrics-text-container-error');
                }
                else {
                    if (i === 0) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                            splitInput.push(" ")
                        }

                        if (splitInput.length === 2) {
                            splitInput.push(" ")
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }
                    if (i === 3) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }

                    lyricsPayload.push(array[i].value)
                }
            }
        }
        console.log(lyricsPayload)
        if (!flag) {
            // api call and redirect to record screen
            props.setLoader(true);
            let response = await authService.sendLyricsAPI("2", lyricsPayload);
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Processing',
                label: 'Kuch Samay'
            });
            await checkTrackStatus(response.id)
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Ready',
                label: 'Kuch Samay'
            });
            props.setLoader(false);
            history.push('/record/' + response.id);
            window.location.reload();

        }
    }

    return (
        <div className={props.className} ref={props.editLyricsRef}>
            <div className="lyrics-container container-fluid d-flex flex-column justify-content-between">
                <div className="crossLyrics d-flex justify-content-between">
                    <div className="lyrics-header-spacer"></div>
                    <div className="edit-text-header">Customize Lyrics</div>
                    <img src={closeIcon} alt="" onClick={props.toggleLyrics} />
                </div>

                <div className="lyrics-text-container d-flex flex-column justify-content-center">
                    <div className="lyrics-line">
                        Kuch samay pehle ki hai yeh baat
                    </div>
                    <div className="lyrics-line">
                        Din tha woh bahut hee khaas
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={eventRef} onChange={e => eventRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='Shaadi hui thi' id='answer2' size='6' />
                            <label htmlFor="answer1">(wedding or birth event)</label>
                        </span> &nbsp;
                        <span className="field-container">
                            <input ref={yearRef} onChange={e => yearRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='1975' id='answer2' size='6' />
                            <label htmlFor="answer1">(enter year)</label>
                        </span>&nbsp;
                        tha year.<br />
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={ageRef} onChange={e => ageRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='21' id='answer3' size='6' />
                            <label htmlFor="answer1">(enter age)</label>
                        </span> saal thi tab meri umar
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={nameRef} onChange={e => nameRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='Ramesh Gupta' id='answer4' size='6' />
                            <label htmlFor="answer1">(name of relative)</label>
                        </span>
                        &nbsp;&nbsp;
                        <span className="field-container">
                            <input ref={relationRef} onChange={e => relationRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='husband' id='answer2' size='6' />
                            <label htmlFor="answer1">(enter relationship)</label>
                        </span> ka naam
                    </div>
                    <div className="lyrics-line">
                        Din tha woh bahut hee khaas
                    </div>
                    <div className="lyrics-line">
                        Kuch samay pehle ki hai yeh baat
                    </div>
                </div>
                <div className="lyrics-controls container-fluid d-flex justify-content-center justify-content-lg-end">
                    {/* <div className="" onClick={props.resetLyrics}>
                        restore
                    </div> */}
                    <div className="save-proceed" onClick={saveLyricsAPI}>
                        save and proceed
                    </div>
                </div>

            </div>
        </div>
    )
};

export default EditLyricsThree;