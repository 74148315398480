import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactGA from 'react-ga';
import { useParams } from "react-router-dom";
//pages
import Controller from './controller'
import VideoPlayer from './videoPlayer';

import MicRecorder from "mic-recorder-to-mp3";
import authService from '../../service/auth.service';
import DownloadController from './downloadController';
import EditLyrics from '../karaoke-booth/editLyrics';
import EditLyricsTwo from '../karaoke-booth/editLyricsTwo';
import EditLyricsThree from '../karaoke-booth/editLyricsThree';
import EditLyricsFour from '../karaoke-booth/editLyricsFour';
import EditLyricsFive from '../karaoke-booth/editLyricsFive';
import EditLyricsSix from '../karaoke-booth/editLyricsSix';
import EditLyricsSeven from '../karaoke-booth/editLyricsSeven';
import EditLyricsEight from '../karaoke-booth/editLyricsEight';

import { useHistory } from 'react-router-dom';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Recording = (props) => {
  let { track_name } = useParams();
  const editLyricsRef = useRef();
  const history = useHistory();

  const [state, setState] = useState({
    play: false,
    downlaodPlay: false,
    name: '',
    mute: false,

    isBlocked: false,
    blobURL: '',
    blob: null,

    VideoProgress: 0,
    VideoPreviewProgress: 0,

    videoPlayingFlag: false,
    videoPreviewPlayingFlag: false,

    disbalePreveiw: true,
    downloadPreview: true,
    loaded: false,
    output_id: ''
  });

  function EditCustomLyrics(props) {
    if (props.name.includes("sheher-english")) {
      return <EditLyricsFive  setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }

    if (props.name.includes("kuch-samay-english")) {
      return <EditLyricsSix  setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }
    if (props.name.includes("one-in-a-million-english")) {
      return <EditLyricsSeven  setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }

    if (props.name.includes("kuch-samay")) {
      return <EditLyricsThree setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }
    if (props.name.includes("one-in-a-million")) {
      return <EditLyricsTwo setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }
    if (props.name.includes("kya-hai-banaya")) {
      return <EditLyricsFour setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }

    if (props.name.includes("sheher")) {
      return <EditLyrics setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn"/>
    }

    if (props.name.includes("what-i-ate")) {
      return <EditLyricsEight setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
    }
    return <div></div>
  }

  const videoRef = useRef(null);
  const downloadVideoRef = useRef(null);
  const getServerVideo = async () => {
    //request made
    console.log(track_name)
    let response = await authService.getVideoByName(track_name);
    console.log(response)
    if (response.status === "ready") {
      setState({ ...state, loaded: true, downloadPreview: true, name: response.name });
    } else {
      setState({ ...state, loaded: true, downloadPreview: false, name: response.name });
    }
    console.log(state.name)

  }
  const checkPermissionForAudio = () => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    //recording start
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        setState({ ...state, isBlocked: false });
      })
      .catch(err => {
        setState({ ...state, isBlocked: true });
        console.log("Please give permission for the microphone to record audio.");
        console.log(err.name + ": " + err.message);
      });
  };


  useEffect(() => {
    checkPermissionForAudio();
  }, []);

  useEffect(() => {
    getServerVideo();
  }, [state.loaded]);


  const editLyrics = () => {
    console.log("edit Lyrics click")
    editLyricsRef.current.classList.remove('edit-lyrics-visible');
  }

  const playAudio = () => {
    console.log("play audio click")
    ReactGA.event({
      category: 'karaoke-booth',
      action: 'Video_Play',
      value: 1
    });
    if (!state.play) {
      if (state.isBlocked) {
        console.log("Please give permission for the microphone to record audio.");
      }
      else {
        //console.log(videoRef.current)
        Mp3Recorder.start()
          .then(() => {
            setState({ ...state, play: true, videoPlayingFlag: true, disbalePreveiw: true })
          })
          .catch(e => console.error(e));
      }
    }
    else {
      //record stop
      Mp3Recorder.stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          videoRef.current.seekTo(0, "fraction")
          setState({ ...state, blob: blob, blobURL: blobURL, play: false, videoPlayingFlag: false, VideoProgress: 0, disbalePreveiw: false });
          console.log("record stop");
        })
        .catch(e => console.log(e));
    }
  }

  const muteVocals = () => {
    console.log("mute audio ");
    setState({ ...state, mute: !state.mute })
  }

  const saveTrackVideo = async () => {
    console.log("save audio click ")
    try {
      let response = await authService.getVideoByName(track_name);
      //download api call
      // var data = response.output_video_without_vocal;
      var data = response.output_video_without_vocal;
      //console.log(data)
      var a = document.createElement("a"); //Create <a>
      a.target = "_blank";
      a.href = data; //Image Base64 Goes here
      a.download = state.name + ".mp4"; //File name Here
      document.body.appendChild(a);
      setTimeout(() => {
        a.click();
      }, 500);
      console.log("downlaod done");
      ReactGA.event({
        category: 'karaoke-booth',
        action: 'Video_Download',
        value: 1
      });
    }
    catch (e) { console.log(e) }

    // console.log(data)
    // window.open(data.output_video, "_blank");

  }
  const saveTrackAudio = async () => {
    console.log("save audio click ")
    //download api call
    // var data = state.downloadVideo;
    // var a = document.createElement("a"); //Create <a>
    // a.href = data; //Image Base64 Goes here
    // a.download = "MTV.mp4"; //File name Here
    // a.click();
    //a.target = "_blank";
    // console.log("downlaod done");
    // console.log(data)
    // window.open(data.output_video, "_blank");

  }
  const shareWhatsapp = async () => {
    let response = await authService.getVideoByName(track_name);
    window.open(
      `https://wa.me/?text=${response.output_video_without_vocal}`,
      '_blank'
    )
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const checkTrackStatus = async (name) => {
    let response = await authService.getVideoByName(name)
    if (response) {
      if (response.status === "ready") {
        return response
      } else {
        await delay(5000);
        return checkTrackStatus(name)
      }
    } else {
      await delay(5000);
      return checkTrackStatus(name)
    }

  }

  const previewTrack = async () => {
    props.setLoader(true)
    let output_data = await authService.sendAudioApi(track_name, state.blob)
    await checkTrackStatus(track_name)
    setState({ ...state, output_id: output_data.id, downloadPreview: true })
    props.setLoader(false);
  }

  const previewStopAudio = async () => {
    downloadVideoRef.current.seekTo(0, "fraction")
    setState({ ...state, VideoPreviewProgress: 0, videoPreviewPlayingFlag: false, downlaodPlay: false });
  }
  const previewPlayAudio = async () => {
    console.log("previewAudioClick")
    setState({ ...state, videoPreviewPlayingFlag: !state.videoPreviewPlayingFlag, downlaodPlay: !state.downlaodPlay })

  }
  const downlaodBack = async () => {
    console.log("download back");
    //window.location.href = "/record" + track_name + "";
    history.push('/karaokebooth');
    props.setCarousalVisible('carousalNotVisible');
  }

  const handleProgressBar = (changeState) => {
    setState({ ...state, VideoProgress: changeState.played * 100 });
  }
  const handlePreviewProgressBar = (changeState) => {
    setState({ ...state, VideoPreviewProgress: changeState.played * 100 });
  }

  const toggleLyrics = () => {
    editLyricsRef.current.classList.add('edit-lyrics-visible')
  }

  return (
    <div>
      {state.name !== "" && <EditCustomLyrics name={state.name} ></EditCustomLyrics>}
      <div className="screen-container transitionAnimationIn">
        {/* {state.loaded === true && (state.downloadPreview === true ? ( */}
        {state.loaded === true && (true ? (
          <>
            <VideoPlayer
              handleProgressBar={handlePreviewProgressBar}
              videoRef={downloadVideoRef}
              output_id={track_name}
              playingFlag={state.videoPreviewPlayingFlag}
              output={true}
            ></VideoPlayer>
            <DownloadController
              VideoPreviewProgress={state.VideoPreviewProgress}
              previewStopAudio={previewStopAudio}
              saveTrackVideo={saveTrackVideo}
              saveTrackAudio={saveTrackAudio}
              previewPlayAudio={previewPlayAudio}
              downlaodBack={downlaodBack}
              state={state}
              shareWhatsapp={shareWhatsapp}
            >
            </DownloadController>
          </>
        ) : (
          <>
            <VideoPlayer
              handleProgressBar={handleProgressBar}
              videoRef={videoRef}
              output_id={track_name}
              playingFlag={state.videoPlayingFlag}
            ></VideoPlayer>
            <Controller
              VideoProgress={state.VideoProgress}
              editLyrics={editLyrics}
              playAudio={playAudio}
              muteVocals={muteVocals}
              previewTrack={previewTrack}
              state={state}>
            </Controller>
          </>
        ))
        }
      </div>
    </div>
  )
};

export default Recording;