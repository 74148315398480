import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';

import './karaoke-booth.css'
import closeIcon from './../../assets/close_white.png'
import { useHistory } from 'react-router-dom';
import authService from '../../service/auth.service';

const EditLyricsTwo = (props) => {
    const history = useHistory();

    const relationRef = useRef();
    const nameRef = useRef();
    const nameRefCopy = useRef();
    const relationRefTwo = useRef();
    const childRef = useRef();
    const childRefCopy = useRef();

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkTrackStatus = async (name) => {
        let response = await authService.getVideoByName(name)
        if (response) {
            return response
        } else {
            await delay(5000);
            return checkTrackStatus(name)
        }
    }

    const saveLyricsAPI = async () => {
        let array = [relationRef.current, nameRef.current, nameRefCopy.current, relationRefTwo.current, childRef.current, childRefCopy.current]
        let i;
        let flag = false;
        let focusFlag = -1;
        let lyricsPayload = []
        for (i = 0; i <= array.length; i++) {
            if (array[i]) {
                if (array[i].value === '') {
                    if (focusFlag === -1) {
                        flag = true;
                        array[i].focus();
                        focusFlag = i;
                    }
                    array[i].classList.add('lyrics-text-container-error');
                }
                else {
                    if (i === 0) {
                        lyricsPayload.push(array[i].value)
                        continue
                    }
                    if (i === 1) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                        }
                        if (splitInput.length === 3) {
                            splitInput[0] = splitInput[0] + " " + splitInput[1]
                            splitInput[1] = splitInput[2]
                            splitInput.pop()
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }
                    if (i === 2) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                        }
                        if (splitInput.length === 3) {
                            splitInput[0] = splitInput[0] + " " + splitInput[1]
                            splitInput[1] = splitInput[2]
                            splitInput.pop()
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }
                    if (i === 3) {
                        lyricsPayload.push(array[i].value)
                        continue
                    }

                    if (i === 4) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                            splitInput.push(" ")
                        }
                        if (splitInput.length === 2) {
                            splitInput.push(" ")
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }
                    if (i === 5) {
                        let splitInput = array[i].value.split(" ")
                        if (splitInput.length === 1) {
                            splitInput.push(" ")
                            splitInput.push(" ")
                        }
                        if (splitInput.length === 2) {
                            splitInput.push(" ")
                        }
                        for (let j = 0; j < splitInput.length; j++) {
                            lyricsPayload.push(splitInput[j])
                        }
                        continue
                    }
                    lyricsPayload.push(array[i].value)
                }
            }
        }
        console.log(lyricsPayload)
        if (!flag) {
            // api call and redirect to record screen
            props.setLoader(true);
            // prod value
            let response = await authService.sendLyricsAPI("134", lyricsPayload);
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Processing',
                label: 'One In A Million (English)'
            });
            await checkTrackStatus(response.id)
            ReactGA.event({
                category: 'karaoke-booth',
                action: 'Lyrics_Ready',
                label: 'One In A Million (English)'
            });
            props.setLoader(false);
            history.push('/record/' + response.id);
            window.location.reload();
        }
    }

    return (
        <div className={props.className} ref={props.editLyricsRef}>
            <div className="lyrics-container container-fluid d-flex flex-column justify-content-between">
                <div className="crossLyrics d-flex justify-content-between">
                    <div className="lyrics-header-spacer"></div>
                    <div className="edit-text-header">Customize Lyrics</div>
                    <img src={closeIcon} alt="" onClick={props.toggleLyrics} />
                </div>

                <div className="lyrics-text-container d-flex flex-column justify-content-start">
                    <div className="lyrics-line">
                        I love my entire family 
                    </div>
                    <div className="lyrics-line">
                        They take really good care of me 
                    </div>
                    <div className="lyrics-line">
                        My 
                        <span className="field-container">
                            <input ref={relationRef} onChange={e => relationRef.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='Husband' id='answer2' size='6' />
                            <label htmlFor="answer1">(enter relationship)</label>
                        </span>
                        &nbsp;
                        name is
                        <span className="field-container">
                            <input ref={nameRef} onChange={e => {
                                nameRef.current.classList.remove("lyrics-text-container-error")
                                nameRefCopy.current.value = nameRef.current.value;
                            }
                            } type="text" className='refresh' placeholder='Vilas Puri' id='answer2' size='6' />
                            <label htmlFor="answer1">(name of relative)</label>
                        </span>
                        &nbsp;,
                        <span className="field-container">
                            <input ref={nameRefCopy} onChange={e => {
                                nameRefCopy.current.classList.remove("lyrics-text-container-error")
                                nameRef.current.value = nameRefCopy.current.value;
                            }} type="text" className='refresh' placeholder='Vilas Puri' id='answer2' size='6' />
                            <label htmlFor="answer1">(name of relative)</label>
                        </span>
                    </div>
                    <div className="lyrics-line">
                        <span className="field-container">
                            <input ref={relationRefTwo} onChange={e => relationRefTwo.current.classList.remove("lyrics-text-container-error")} type="text" className='refresh' placeholder='Kids' id='answer3' size='6' />
                            <label htmlFor="answer1">(enter relationship)</label>
                        </span>
                            are
                        <span className="field-container">
                            <input ref={childRef} onChange={e => {
                                childRef.current.classList.remove("lyrics-text-container-error")
                                childRefCopy.current.value = childRef.current.value;
                            }} type="text" className='refresh' placeholder='Amita and Varun' id='answer4' size='6' />
                            <label htmlFor="answer1">(name/names of relative)</label>
                        </span>,
                        <span className="field-container">
                            <input ref={childRefCopy} onChange={e => {
                                childRefCopy.current.classList.remove("lyrics-text-container-error")
                                childRef.current.value = childRefCopy.current.value;
                            }} type="text" className='refresh' placeholder='Amita and Varun' id='answer4' size='6' />
                            <label htmlFor="answer1">(name/names of relative)</label>
                        </span>
                    </div>
                    <div className="lyrics-line">
                        My family is one in a million for me.
                    </div>
                </div>
                <div className="lyrics-controls container-fluid d-flex justify-content-center justify-content-lg-end">
                    {/* <div className="" onClick={props.resetLyrics}>
                        restore
                    </div> */}
                    <div className="save-proceed" onClick={saveLyricsAPI}>
                        save and proceed
                    </div>
                </div>

            </div>
        </div >
    )
};

export default EditLyricsTwo;