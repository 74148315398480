import React, { useEffect, useState } from 'react';
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { TextPlugin } from 'gsap/TextPlugin';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import './main.css';
import heroIcon from './../../assets/logo-gif.gif';
import presentIcon from './../../assets/presents.png';
import familyIcon from './../../assets/family.png';
import familyBlackIcon from './../../assets/family_black.png';
import associationIcon from './../../assets/association.png';
import instaIcon from './../../assets/instagram.png';
import fbIcon from './../../assets/facebook.png';
import { karokeBooth } from '../header/header';
import CarousalPage from './carousal';

const Main = () => {
    // var trigger1 = document.getElementById('trigger1');
    // var animate1 = document.getElementById('trigger1');
    const [disc_toggle, setDiscToggle] = useState(false);
    const toggleDisc = () => {
        //setDiscToggle(!disc_toggle);
    }
    useEffect(() => {
        ScrollMagicPluginGsap(ScrollMagic, gsap);
        gsap.registerPlugin(TextPlugin);
        var controller = new ScrollMagic.Controller();
        var tl = gsap.timeline();
        tl.fromTo("#right1", { filter: 'blur(4px)', duration: 0.5 }, { filter: 'blur(16px)', duration: 1 });
        tl.to("#animate2", { autoAlpha: 0, ease: "power1.out", duration: 0.5 }, 1);
        tl.set('#animate2', { text: "It becomes easier to remember, retain and recall information when presented <span style='font-family: quicksand-bold'> in the form of a song that is sung along.</span> <br /><br /> <span style='font-weight: 800'>The American Music Therapy Association</span> states that therapists across the world use music as therapy for those living with early Alzheimer’s for its ability in helping improve memory and mood." });
        tl.fromTo('#title-reveal', { autoAlpha: 0, y: 24 }, { autoAlpha: 1, y: 0 }, 1);
        tl.fromTo('#animate1', { color: '#212529' }, { color: '#CB57BB' }, 1);
        tl.fromTo('#right2 #icon-back1', { scale: 0 }, { scale: 1 }, 1.1);
        tl.fromTo('#right2 #icon-back2', { scale: 0 }, { scale: 1.2 }, 1.2);
        tl.fromTo('#right2 #icon-back3', { scale: 0 }, { scale: 1.4 }, 1.3);
        tl.fromTo('#right2 img', { autoAlpha: 0 }, { autoAlpha: 1 }, 1.4);
        tl.to("#animate2", { autoAlpha: 1, ease: "power1.out", duration: 0.7 });
        var scene = new ScrollMagic.Scene({
            triggerElement: "#pin1", duration: 1500, triggerHook: 'onLeave',
        })
            .setPin("#pin1")
            .addTo(controller)
            .setTween(tl);
        //console.log(scene);
    }, []);
    return (
        <div id="superwrapper" className="scrollContainer">
            <div className="home-container">
                <div className="landing-section container d-flex flex-column flex-xl-row justify-content-lg-center align-items-lg-center">
                    <div className="cta-section">
                        <div className="desktop">
                            A one-of-its kind <br /> customizable <br /> karaoke <br /><span style={{ fontFamily: 'quicksand-bold' }}>that aids <br /> memory <br /> enhancement <br />  in early Alzheimer’s </span>
                        </div>
                        <div className="mobile">
                            A one-of-its kind customizable karaoke <br /> <span style={{ fontFamily: 'quicksand-bold' }}>that aids memory enhancement in early Alzheimer’s </span>
                        </div>
                        <div className="cta-button" onClick={karokeBooth}>
                            go to karaoke booth
                        </div>
                    </div>
                    <div className="landing-hero">
                        {/* <img className="presents" src={presentIcon} alt="" /> */}
                        <img className="hero-img" src={heroIcon} alt="" />
                        {/* <div className="association">
                            <div style={{marginBottom: '4px'}}>In association with</div>
                            <img src={associationIcon} alt="" />
                        </div> */}
                    </div>
                    <div className="landing-text" >
                        Made specifically for those living with early Alzheimer’s, MTV Memory Karaoke is an innovative music platform that provides caregivers the convenience to customize songs and create their own versions, <span style={{ fontWeight: 800 }}>using information commonly forgotten by the affected family member. </span>
                        <br /><br />
                        <span style={{ fontFamily: 'quicksand-bold' }}>The songs should be practiced daily in the company of caregivers. Over time, the 4 customizable songs will aid the person living with early Alzheimer’s to sing in a manner comfortable to them and retain vital information with respect to their own lives.</span>
                    </div>
                </div>
                <div id="video-section">
                    <br />
                    <div>
                        <h3>Helping those with early Alzheimer’s with Memory Karaoke</h3>
                    </div>
                    <br />
                    <video src="https://memory-karaoke.s3.ap-south-1.amazonaws.com/mtv.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen controls />
                </div>
                <div id="pin1" className="info-section d-flex flex-column flex-xl-row justify-content-lg-center align-items-lg-center">
                    <div className="info-left">
                        <div id="animate1" className="info-left1">
                            Alzheimer's <span id="title-reveal">X memory X music</span>
                        </div>
                        <div id="animate2" className="info-left2">
                            Alzheimer's is the most common cause of dementia, a general term for memory loss and other cognitive abilities serious enough to interfere with daily life.
                            <span style={{ fontWeight: 800 }}> India ranks 3rd worldwide, with 5 out of every 100 elderly Indians having dementia.</span>
                            <br /><br />
                            Some of the most common symptoms include <span style={{ fontWeight: 800 }}>forgetting vital information and changes to mood and social behaviour.</span>
                            <br /><br />
                            To learn a little more about Alzheimer’s, visit the website of our knowledge partners, the Alzheimer’s and Related Disorders Society of India (ARDSI).<br />
                        </div>
                    </div>
                    <div className="info-right">
                        <div id="right1">
                            <img src={familyBlackIcon} alt="" />
                        </div>
                        <div id="right2">
                            <div id="icon-back1"></div>
                            <div id="icon-back2"></div>
                            <div id="icon-back3"></div>
                            <img src={familyIcon} alt="" />
                        </div>
                    </div>
                </div>
                {/* <CarousalPage /> */}
                <div className="footer-section d-flex flex-column justify-content-between">
                    <div className="mob">
                        <img />
                    </div>
                    <div className="footer-links d-flex flex-column justify-content-center align-items-center">
                        <a href="https://ardsi.org/" target="_blank"><img className="ardsi" src={associationIcon} alt="" /></a> <br /> <br />
                        <div>
                            <div className="tele-sub">For professional care, take support from specialists.</div>
                            <span><a href="tel:+919846198471">+91 98461 98471</a>  | <a href="tel:+919846198473">+91 98461 98473</a> | <a href="tel:+919846198786">+91 98461 98786</a></span>
                        </div>
                        {/* <a href="https://ardsi.org/" target="_blank">Alzheimer's & Related Disorders Society of India</a> */}
                    </div>
                    <div className="footer-social d-flex justify-content-between">
                        <div className="disclaimer" onClick={toggleDisc}>
                            Disclaimer
                        </div>
                        <div>
                            Website made by <a href="https://www.ronin.co.in" target="_blank">Ronin Labs</a>
                        </div>
                        <div className="social-icons d-flex">
                            <a href="https://www.facebook.com/mtvindia" target="_blank"><img src={fbIcon} alt="" /></a>
                            <a href="https://www.instagram.com/mtvindia/" target="_blank"><img src={instaIcon} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="disclaimer-modal-container" className="" className={disc_toggle ? 'd-flex justify-content-center align-items-center open' : 'd-flex justify-content-center align-items-center'}>
                <div className="modal-back" onClick={toggleDisc}></div>
                <div className="modal-body">
                    abc
                </div>
            </div>
        </div>
    )
};

export default Main;
