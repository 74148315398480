import React, { useEffect, useRef, useState } from 'react';
import './loader.css';

const Loader = () => {


  const messages = ["Your very own personalised track is being created. Just give us a minute.", "Your very own personalised track is being created. Just give us a minute.", "Your very own personalised track is being created. Just give us a minute."];
  const [messageIndex, setMessageIndex] = useState(0);
  useEffect(() => {
    // Move on to the next message every `n` milliseconds
    let timeout;
    if (messageIndex < messages.length - 1) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [messages, messageIndex]);

  return (
    <>
      <div id="preloader">
        <div class="blobs">
          <div class="blob center"></div>
          <div class="blob left"></div>
          <div class="blob center"></div>
          <div class="blob right"></div>
          <div class="blob center"></div>
          <div class="blob left"></div>
        </div>
        <div className="preloader-message" style={{ position: 'absolute', marginTop: "5%", left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
          {messages[messageIndex]}
        </div>
      </div>
    </>
  )
}

export default Loader;
