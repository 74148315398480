import React from 'react';
import './karaoke-booth.css'


const Track = (props) => {
    return (
        <div className="track">
            <div className={'track-header ' + props.className}>
                <img className="track-icon" src={props.Icon} alt="" />
                <div className="head-text">{props.headerText}</div>
            </div>
            <div className="track-body">
                <div className="track-name">
                    {props.name}
                </div>
                <div className="track-description">
                    {props.description}
                </div>
                {/* <div onClick={props.preview} className="preview-button"> */}
                <div className="preview-button" onClick={props.preview}>
                    <div className="preview-button-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.97 16.13"><title>triangle</title><g id="Layer_2" data-name="Layer 2"><g id="Work"><polygon points="13.97 8.07 6.99 12.1 0 16.13 0 8.07 0 0 6.99 4.03 13.97 8.07" /></g></g></svg>
                    </div>
                    <div className="preview-button-text">
                        preview
                    </div>
                </div>
                <div onClick={props.recordTrack} className={`select ` + props.className}>
                    select track
                </div>
            </div>
        </div>
    )
};

export default Track;
