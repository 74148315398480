import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";

//pages
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import RecordingScreen from "./components/recording/recording";
import KarokeBooth from "./components/karaoke-booth/karaoke-booth";
import Main from "./components/home/main";
import Loader from "./components/loader/loader";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import CarousalPage from "./components/home/carousal";


function App() {
  const [loader, setLoader] = useState(false);
  const [downloadPreview, setDownlaodPreview] = useState(false);
  const [carousalVisible, setCarousalVisible] = useState("carousalVisible")
  //const [carousalVisible, setCarousalVisible] = useState("carousalVisible")

  if (!localStorage.getItem('carousalVisible')) localStorage.setItem('carousalVisible', 'true')
  if (!localStorage.getItem('finishCarousal')) localStorage.setItem('finishCarousal', 'false');


  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Header carousalVisible={carousalVisible} setCarousalVisible={setCarousalVisible} />
        <Switch>
          {loader === true ? (
            <Loader></Loader>
          ) : (
            <>
              <Route exact path="/" component={Main} />
              <Route
                exact
                path="/karaokebooth"
                component={() => {
                  return (
                    <>
                      {localStorage.getItem('finishCarousal') === "true" && localStorage.getItem("carousalVisible") === "true" ? (
                        <KarokeBooth setLoader={setLoader} />
                      ) : (
                        <>
                          <CarousalPage carousalVisible={carousalVisible} setCarousalVisible={setCarousalVisible} />
                          <KarokeBooth setLoader={setLoader} />
                        </>
                      )}
                    </>)
                }
                }
              />
              <Route
                exact
                path="/record/:track_name"
                component={() => (
                  <RecordingScreen
                    setLoader={setLoader}
                    setDownlaodPreview={setDownlaodPreview}
                    setCarousalVisible={setCarousalVisible}
                  />
                )}
              />
            </>
          )}
          {/* <Route exact path="/" component={RecordingScreen} /> */}
        </Switch>
        <Footer />
      </BrowserRouter>
    </div >
    // <CarousalPage />
  );
}

export default App;


// {localStorage.getItem('carousalVisible') === 'false' ? (
//   < CarousalPage carousalVisible={carousalVisible} setCarousalVisible={setCarousalVisible} />
// ) : (
//   <>
//     {
//       localStorage.getItem('finishCarousal') === 'false' ? (
//         < CarousalPage carousalVisible={carousalVisible} setCarousalVisible={setCarousalVisible} />
//       ) : (
//         <KarokeBooth setLoader={setLoader} />)
//     }