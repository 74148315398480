import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { v1 as uuidv4 } from "uuid";
import familyIcon from './../../assets/family_black.png';
import calenderIcon from './../../assets/calender_black.png';
import foodIcon from './../../assets/food_black.png';
import homeIcon from './../../assets/house_black.png';
import closeIcon from './../../assets/close.png';
import { useHistory } from 'react-router-dom';

import './carousal.css'
import { karokeBooth } from '../header/header';
import ReactGA from 'react-ga';


const CarousalPage = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        goToSlide: 0,
        //offsetRadius: 2,
        showNavigation: true,
        disabled: true,
        next: "next"
    });

    const oneRef = useRef();
    const twoRef = useRef();
    const threeRef = useRef();

    useEffect(() => {
        switch (state.goToSlide) {
            case 0:
                console.log("hello")
                oneRef.current.classList.remove("disable");
                twoRef.current.classList.add("disable");
                threeRef.current.classList.add("disable");
                break;
            case 1:
                oneRef.current.classList.add("disable");
                oneRef.current.classList.add("transitionAnimationOut");
                twoRef.current.classList.add("transitionAnimationIn");
                twoRef.current.classList.remove("disable");
                threeRef.current.classList.add("disable");
                break;
            case 2:
                oneRef.current.classList.add("disable");
                twoRef.current.classList.add("disable");
                twoRef.current.classList.add("transitionAnimationOut");
                threeRef.current.classList.add("transitionAnimationIn");
                threeRef.current.classList.remove("disable");
                break;
            case 4:
                break;
            default:
                break;
        }
    }, [state.goToSlide])


    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    const previousClick = () => {
        console.log("previous");
        if (state.goToSlide > 0) {
            if ((state.goToSlide - 1) === 0) setState({ ...state, disabled: true, goToSlide: (state.goToSlide - 1) });
            else {
                setState({ ...state, next: "next", goToSlide: (state.goToSlide - 1) });
            }
        }
    }


    const nextClick = () => {
        console.log("next");
        if (state.goToSlide < 2) {
            if ((state.goToSlide + 1) === 2) setState({ ...state, next: "finish", goToSlide: (state.goToSlide + 1) });
            else setState({ ...state, disabled: false, goToSlide: (state.goToSlide + 1) });
        }
        else {
            //props.karokeBooth(); //redirect to karokeBooth
            //disable key
            localStorage.setItem('carousalVisible', 'true')
            localStorage.setItem('finishCarousal', 'true')
            props.setCarousalVisible('carousalNotVisible');
            karokeBooth();
            ReactGA.event({ category: 'turorial_end', action: 'Sent message' });
        }
    }

    const closeCarousal = () => {
        localStorage.setItem('carousalVisible', 'true')
        localStorage.setItem('finishCarousal', 'false')
        props.setCarousalVisible('carousalNotVisible');
        karokeBooth()
        ReactGA.event({ category: 'turorial_end', action: 'Sent message' });
    }

    let slides = [
        {
            key: uuidv4(),
            content: <>
                <div className="d-flex slide1 align-items-center justify-content-between">
                    <div className="slide1-track track1">
                        <img src={homeIcon} alt="" />
                    </div>
                    <div className="slide1-track track2">
                        <img src={familyIcon} alt="" />
                    </div>
                    <div className="slide1-track track3">
                        <img src={calenderIcon} alt="" />
                    </div>
                    <div className="slide1-track track4">
                        <img src={foodIcon} alt="" />
                    </div>
                    <div className="slide1-mouse"></div>
                </div>
            </>
        },
        {
            key: uuidv4(),
            content: <>
                <div className="slide2">
                    <div className="slide2-line">Hello hello mic testing…1…2…3… Go!</div>
                    <div className="slide2-placeholder placeholder1">enter name</div>
                    <div className="slide2-line">hai mera naam</div>
                    <div className="slide2-placeholder placeholder2">enter name</div>
                    <div className="slide2-line">hai mera naam</div>
                    <div className="slide2-underline1"></div>
                    <div className="slide2-underline2"></div>
                    <div className="slide2-text1">Prakash Rao</div>
                    <div className="slide2-text2">Prakash Rao</div>
                </div>
            </>
        },
        {
            key: uuidv4(),
            content: <>
                <div className="slide3">
                    <div className="slide3-music1"></div>
                    <div className="slide3-music2"><img src={homeIcon} alt="" /></div>
                    <div className="slide3-progress-back">
                        <div className="slide3-progress"></div>
                    </div>
                    <div className="slide3-controls d-flex justify-content-between align-items-center">
                        <div className="slide3-control"></div>
                        <div className="slide3-control"></div>
                        <div className="slide3-control"></div>
                    </div>
                </div>
            </>
        },
        {
            key: uuidv4(),
            content: <>
                <div>fourth Inst</div>
            </>
        },
    ]

    return (
        <>
            <div className={`modalCarousel ` + props.carousalVisible + ``}>
                <div className="divContainer container-fluid tutorial-section d-flex flex-column flex-xl-row justify-content-center">
                    <div onClick={closeCarousal} className="close-carousal">
                        <img src={closeIcon} alt="" />
                    </div>
                    <div className="tutorial-left">
                        <Carousel id="carousel" slides={slides}
                            goToSlide={state.goToSlide}
                        />
                    </div>

                    <div className="tutorial-right d-flex flex-column justify-content-center">
                        <div ref={oneRef} className="oneClass disable">
                            <h2>How to use</h2>
                            <h3>
                                #1 SELECT TRACK
                            </h3>
                            <p>
                                Below are 4 specially crafted customizable songs based on
                                vital information commonly forgotten by those with early Alzheimer's:<br /> <br />
                                1. Address<br />
                                2. Names of relatives &amp; caregivers<br />
                                3. Important life events<br />
                                4. Reminder for meals eaten<br />
                            </p>
                        </div>

                        <div ref={twoRef} className="twoClass disable">
                            <h2>How to use</h2>
                            <h3>
                                #2 FILL IN THE LYRICS
                            </h3>
                            <p>
                                Once you select a track, fill in the lyrics with the personal details of the family member with early Alzheimer's.
                            </p>
                        </div>


                        <div ref={threeRef} className="threeClass disable">
                            <h2>How to use</h2>
                            <h3>
                                #3 SING AND PRACTISE DAILY
                            </h3>
                            <p>
                                With your very own customized karaoke song generated, you can now download it and sing along with the person with early Alzheimer's.

                                {/* <div className="cta-button" onClick={karokeBooth}>try it out now</div> */}
                            </p>
                        </div>

                        <div className="tutorial-controls d-flex justify-content-between">
                            <div id="prev" disabled={state.disabled} onClick={previousClick}>previous</div>
                            <div id="next" onClick={nextClick}>{state.next}</div>
                        </div>
                    </div>
                </div>
                <div className="caraousal-filler"></div>
            </div>
        </>
    )
};

export default CarousalPage;

