import React, { useRef, useState } from 'react';
import ReactGA from 'react-ga';

import './karaoke-booth.css'
import track1Icon from './../../assets/home.png';
import track2Icon from './../../assets/calender.png';
import track3Icon from './../../assets/family.png';
import track4Icon from './../../assets/food.png';
import Track from './track'
import EditLyrics from './editLyrics';
import PreviewLyrics from './previewLyrics';
import EditLyricsTwo from './editLyricsTwo';
import EditLyricsThree from './editLyricsThree';
import EditLyricsFour from './editLyricsFour';
import EditLyricsFive from './editLyricsFive';
import EditLyricsSix from './editLyricsSix';
import EditLyricsSeven from './editLyricsSeven';
import EditLyricsEight from './editLyricsEight';

import PreviewLyricsTwo from './previewLyricsTwo';
import PreviewLyricsThree from './previewLyricsThree';
import PreviewLyricsFour from './previewLyricsFour';
import PreviewLyricsFive from './previewLyricsFive';
import PreviewLyricsSix from './previewLyricsSix';
import PreviewLyricsSeven from './previewLyricsSeven';
import PreviewLyricsEight from './previewLyricsEight';


const KaraokeBooth = (props) => {

    const editLyricsRef = useRef();
    const editLyricsRefTwo = useRef();
    const editLyricsRefThree = useRef();
    const editLyricsRefFour = useRef();
    const editLyricsRefFive = useRef();
    const editLyricsRefSix = useRef();
    const editLyricsRefSeven = useRef();
    const editLyricsRefEight = useRef();

    const previewLyricsRef = useRef();
    const previewLyricsRefTwo = useRef();
    const previewLyricsRefThree = useRef();
    const previewLyricsRefFour = useRef();
    const previewLyricsRefFive = useRef();
    const previewLyricsRefSix = useRef();
    const previewLyricsRefSeven = useRef();
    const previewLyricsRefEight = useRef();

    //trackrecord click
    const trackOneRecord = () => {
        editLyricsRef.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'Sheher'
        });
    }
    const trackTwoRecord = () => {
        //console.log("2R")
        editLyricsRefTwo.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'One In A Million'
        });
    }
    const trackThreeRecord = () => {
        //console.log("3R")
        editLyricsRefThree.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'Kuch Samay'
        });
    }
    const trackFourRecord = () => {
        //console.log("3R")
        editLyricsRefFour.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'Kya Hai Banaya'
        });
    }

    const trackFiveRecord = () => {
        //console.log("3R")
        editLyricsRefFive.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'Sheher (English)'
        });
    }

    const trackSixRecord = () => {
        //console.log("3R")
        editLyricsRefSix.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'Kuch Samay (English)'
        });
    }

    const trackSevenRecord = () => {
        //console.log("3R")
        editLyricsRefSeven.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'One in a million (English)'
        });
    }

    const trackEightRecord = () => {
        //console.log("3R")
        editLyricsRefEight.current.classList.remove('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.add('no-scroll');
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Song_written',
            label: 'kya hai banaya (English)'
        });
    }

    //toggleedit Lyrics
    const toggleLyrics = () => {
        editLyricsRef.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    const toggleLyricsTwo = () => {
        editLyricsRefTwo.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    const toggleLyricsThree = () => {
        editLyricsRefThree.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    const toggleLyricsFour = () => {
        editLyricsRefFour.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }

    const toggleLyricsFive = () => {
        editLyricsRefFive.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    const toggleLyricsSix = () => {
        editLyricsRefSix.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    const toggleLyricsSeven = () => {
        editLyricsRefSeven.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }

    const toggleLyricsEight = () => {
        editLyricsRefEight.current.classList.add('edit-lyrics-visible')
        document.getElementById('karaoke-screen-container').classList.remove('no-scroll');
    }
    //customizedprevview lyrics
    const customizeOneLyrics = () => {
        // console.log("customizeLyrics")
        previewLyricsRef.current.classList.remove('transitionAnimationIn')
        // //previewLyricsRef.current.classList.add('transitionAnimationOut')
        trackOneRecord();
        togglePreviewCross();
    }
    const customizeTwoLyrics = () => {
        previewLyricsRefTwo.current.classList.remove('transitionAnimationIn')
        //previewLyricsRef.current.classList.add('transitionAnimationOut')
        trackTwoRecord();
        togglePreviewCrossTwo();
    }
    const customizeThreeLyrics = () => {
        previewLyricsRefThree.current.classList.remove('transitionAnimationIn')
        //previewLyricsRef.current.classList.add('transitionAnimationOut')
        trackThreeRecord();
        togglePreviewCrossThree();
    }
    const customizeFourLyrics = () => {
        previewLyricsRefFour.current.classList.remove('transitionAnimationIn')
        //previewLyricsRef.current.classList.add('transitionAnimationOut')
        trackFourRecord();
        togglePreviewCrossFour();
    }

    const customizeFiveLyrics = () => {
        previewLyricsRefFive.current.classList.remove('transitionAnimationIn')
        //previewLyricsRef.current.classList.add('transitionAnimationOut')
        trackFiveRecord();
        togglePreviewCrossFive();
    }

    const customizeSixLyrics = () => {
        previewLyricsRefSix.current.classList.remove('transitionAnimationIn')
        trackSixRecord();
        togglePreviewCrossSix();
    }

    const customizeSevenLyrics = () => {
        previewLyricsRefSeven.current.classList.remove('transitionAnimationIn')
        trackSevenRecord();
        togglePreviewCrossSeven();
    }

    const customizeEightLyrics = () => {
        previewLyricsRefEight.current.classList.remove('transitionAnimationIn')
        trackEightRecord();
        togglePreviewCrossEight();
    }

    const togglePreviewCross = () => {
        previewLyricsRef.current.classList.add('preview-lyrics-visible')
    }
    const togglePreviewCrossTwo = () => {
        previewLyricsRefTwo.current.classList.add('preview-lyrics-visible')
    }
    const togglePreviewCrossThree = () => {
        previewLyricsRefThree.current.classList.add('preview-lyrics-visible')
    }
    const togglePreviewCrossFour = () => {
        previewLyricsRefFour.current.classList.add('preview-lyrics-visible')
    }

    const togglePreviewCrossFive = () => {
        previewLyricsRefFive.current.classList.add('preview-lyrics-visible')
    }

    const togglePreviewCrossSix = () => {
        previewLyricsRefSix.current.classList.add('preview-lyrics-visible')
    }

    const togglePreviewCrossSeven = () => {
        previewLyricsRefSeven.current.classList.add('preview-lyrics-visible')
    }

    const togglePreviewCrossEight = () => {
        previewLyricsRefEight.current.classList.add('preview-lyrics-visible')
    }


    //trackpreview click
    const trackOnePreview = () => {
        //setSheher("./../../assets/Sheher_Final_preview.mp4")
        previewLyricsRef.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'Sheher'
        });

        //setNumber("1");
    }
    const trackTwoPreview = () => {
        previewLyricsRefTwo.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'One In A Million'
        });

        //setNumber("2");
    }
    const trackThreePreview = () => {
        //props.setLoader(true)
        //setTimeout(() => {
        //props.setLoader(false)
        previewLyricsRefThree.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'Kuch Samay'
        });
        //}, 2000)
        //setNumber("3");
    }
    const trackFourPreview = () => {
        //console.log("4p")
        previewLyricsRefFour.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'Kya Hai Banaya'
        });
    }

    const trackFivePreview = () => {
        //console.log("4p")
        previewLyricsRefFive.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'Sheher (English)'
        });
    }

    const trackSixPreview = () => {
        //console.log("4p")
        previewLyricsRefSix.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'Sheher (English)'
        });
    }
    const trackSevenPreview = () => {
        //console.log("4p")
        previewLyricsRefSeven.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'One in a million (English)'
        });
    }

    const trackEightPreview = () => {
        //console.log("4p")
        previewLyricsRefEight.current.classList.remove('preview-lyrics-visible')
        ReactGA.event({
            category: 'karaoke-booth',
            action: 'Preview_song',
            label: 'kya hai banaya (English)'
        });
    }

    return (
        <div>
            <PreviewLyrics className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" togglePreviewCross={togglePreviewCross} customizeLyrics={customizeOneLyrics} previewLyricsRef={previewLyricsRef}></PreviewLyrics>
            <PreviewLyricsTwo className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefTwo} togglePreviewCross={togglePreviewCrossTwo} customizeLyrics={customizeTwoLyrics} />
            <PreviewLyricsThree className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefThree} togglePreviewCross={togglePreviewCrossThree} customizeLyrics={customizeThreeLyrics} />
            <PreviewLyricsFour className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefFour} togglePreviewCross={togglePreviewCrossFour} customizeLyrics={customizeFourLyrics} />
            <PreviewLyricsFive className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefFive} togglePreviewCross={togglePreviewCrossFive} customizeLyrics={customizeFiveLyrics} />
            <PreviewLyricsSix className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefSix} togglePreviewCross={togglePreviewCrossSix} customizeLyrics={customizeSixLyrics} />
            <PreviewLyricsSeven className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefSeven} togglePreviewCross={togglePreviewCrossSeven} customizeLyrics={customizeSevenLyrics} />
            <PreviewLyricsEight className="preview-lyrics-float preview-lyrics-visible transitionAnimationIn" previewLyricsRef={previewLyricsRefEight} togglePreviewCross={togglePreviewCrossEight} customizeLyrics={customizeEightLyrics} />
            
            <EditLyrics setLoader={props.setLoader} toggleLyrics={toggleLyrics} editLyricsRef={editLyricsRef} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsTwo setLoader={props.setLoader} toggleLyrics={toggleLyricsTwo} editLyricsRef={editLyricsRefTwo} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsThree setLoader={props.setLoader} toggleLyrics={toggleLyricsThree} editLyricsRef={editLyricsRefThree} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsFour setLoader={props.setLoader} toggleLyrics={toggleLyricsFour} editLyricsRef={editLyricsRefFour} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsFive setLoader={props.setLoader} toggleLyrics={toggleLyricsFive} editLyricsRef={editLyricsRefFive} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsSix setLoader={props.setLoader} toggleLyrics={toggleLyricsSix} editLyricsRef={editLyricsRefSix} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsSeven setLoader={props.setLoader} toggleLyrics={toggleLyricsSeven} editLyricsRef={editLyricsRefSeven} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <EditLyricsEight setLoader={props.setLoader} toggleLyrics={toggleLyricsEight} editLyricsRef={editLyricsRefEight} className="edit-lyrics-float edit-lyrics-visible transitionAnimationIn" />
            <div id="karaoke-screen-container" className="karaoke-screen-container container-fluid">
                <div className="karaoke-content-container d-lg-flex flex-lg-column justtify-content-md-center">
                    <div className="karaoke-headers">
                        <div className="main-heading">
                            karaoke booth
                        </div>
                        <div className="subheading">
                            The songs should be practiced daily in the company of caregivers. <br clasName="desk" /> Over time, the 4 customizable songs will aid the person living with early Alzheimer’s to sing in a manner comfortable for them.
                        </div>
                    </div>
                    <div className="language-title">
                            English
                        </div>
                    <div className="tracklist-container d-flex flex-wrap justify-content-center">
                        <Track Icon={track1Icon} headerText="Address" name={''} preview={trackFivePreview} recordTrack={trackFiveRecord} className={"one"} description={"A song that supports retention of home address when practiced repeatedly over time."}></Track>
                        <Track Icon={track2Icon} headerText="Important Life Events" name={''} preview={trackSixPreview} recordTrack={trackSixRecord} className={"three"} description={"A song that supports retention of important life memories when practiced repeatedly over time."}></Track>
                        <Track Icon={track3Icon} headerText="Names of Relatives" name={''} preview={trackSevenPreview} recordTrack={trackSevenRecord} className={"two"} description={"A song that supports retention of family member names when practiced repeatedly over time."}></Track>
                        <Track Icon={track4Icon} headerText="Daily Meal Reminder" name={''} preview={trackEightPreview} recordTrack={trackEightRecord} description={"A song that supports retention of meals eaten when practiced repeatedly."} className={"four"}></Track>
                    </div>
                    <div className="language-title">
                            Hindi
                        </div>
                    <div className="tracklist-container d-flex flex-wrap justify-content-center">
                        <Track Icon={track1Icon} headerText="Address" name={'Sheher'} preview={trackOnePreview} recordTrack={trackOneRecord} className={"one"} description={"A song that supports retention of home address when practiced repeatedly over time."}></Track>
                        <Track Icon={track2Icon} headerText="Important Life Events" name={'Kuch Samay'} preview={trackThreePreview} recordTrack={trackThreeRecord} className={"three"} description={"A song that supports retention of important life memories when practiced repeatedly over time."}></Track>
                        <Track Icon={track3Icon} headerText="Names of Relatives" name={'One in a million'} preview={trackTwoPreview} recordTrack={trackTwoRecord} className={"two"} description={"A song that supports retention of family member names when practiced repeatedly over time."}></Track>
                        <Track Icon={track4Icon} headerText="Daily Meal Reminder" name={'Kya hai banaya'} preview={trackFourPreview} recordTrack={trackFourRecord} description={"A song that supports retention of meals eaten when practiced repeatedly."} className={"four"}></Track>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default KaraokeBooth;