import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player'
import crossClose from './../../assets/close_white.png';
import ProgressBar from '../recording/progressBar';
import recIcon from './../../assets/play_white.png'
import pauseIcon from './../../assets/pause_white.png'
import saveIcon from './../../assets/download.png'
import stopIcon from './../../assets/stop_white.png'
import backIcon from './../../assets/left_arrow.png'
import track1Icon from './../../assets/family.png'
import video from "./../../assets/Kuch_samay_Final_Preview.mp4"

const PreviewLyricsThree = (props) => {
    const videoRef = useRef();
    const [play, setPlay] = useState(false);
    const [progress, setProgress] = useState(0);

    const handlePlay = () => {
        setPlay(!play)
    }
    useEffect(() => {
        //api Call
        //video=getPreviewVideo("3");

    }, []);
    const previewClose = () => {
        props.togglePreviewCross();
        handleStop();
    }
    const handleStop = () => {
        videoRef.current.seekTo(0, "fraction")
        setPlay(false);
        setProgress(0);
    }
    const handleProgressBar = (changeState) => {
        setProgress(changeState.played * 100)
    }

    return (
        <>
            <div className={props.className} ref={props.previewLyricsRef}>
                <div className="lyrics-container container-fluid d-flex flex-column justify-content-between" style={{ position: "absolute", zIndex: "5", width: "350", height: "100" }}>

                    <div className="d-flex justify-content-between">
                        <div className="preview-fixer" style={{ flex: 1 }}>
                            <img src="" alt="" />
                        </div>
                        {/* <div style={{ flex: 1 }} className="preview-icon">
                            <img src={track1Icon} alt="" />
                        </div> */}
                        <div style={{ flex: 1 }} className="crossLyrics" onClick={previewClose} >
                            <img src={crossClose} />
                        </div>
                    </div>

                    <ReactPlayer
                        ref={videoRef}
                        url={process.env.REACT_APP_API_URL + "preview_video/one-in-a-million-english.mp4"}
                        muted={false}
                        playing={play}
                        width={"100%"}
                        onProgress={handleProgressBar}
                    />
                    <div className="preview-controller">
                        <ProgressBar bgcolor="black" progress={progress} height={4} />
                        <div className='container-fluid d-flex justify-content-between '>
                            <div className="left-controller right-controller d-flex justify-content-start">
                            </div>
                            <div className='center-controller d-flex justify-content-md-center justify-content-sm-center'>
                                {
                                    play === false ?
                                        (
                                            <div className="center-control rec" onClick={handlePlay}>
                                                <img key={'1'} src={recIcon} />
                                                <div className="control-text">play</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="center-control rec" onClick={handlePlay}>
                                                <img key={'2'} src={pauseIcon} />
                                                <div className="control-text">pause</div>
                                            </div>
                                        )
                                }
                                <div className="center-control stop download" onClick={handleStop}>
                                    <img key={'3'} src={stopIcon} />
                                    <div className="control-text">stop</div>
                                </div>

                                {/* <div className="save-container mob download" style={{ flex: 1, marginLeft: '16px', visibility: "hidden" }} onClick={props.customizeLyrics}>
                                    <div className="control-text customize">customize</div>
                                </div> */}
                            </div>

                            <div className="right-controller d-flex justify-content-end" style={{ visibility: "hidden" }}>
                                {/* <div className="save-container desk download" onClick={props.customizeLyrics}>
                                    <div className="control-text customize">customize</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PreviewLyricsThree;